<template>
  <section class="bg-light h-100 p-3">
    <h2>Liste des postulants</h2>
    <hr />
    <div>
      <table class="table table-hover table-responsive">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nom prénom</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            <th scope="col" class="text-center" colspan="2">Pieces jointes</th>
            <th scope="col" class="text-center" colspan="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Mark</td>
            <td>Otto</td>
            <td class="text-center">
              <div><IcoFile /></div>
            </td>
            <td class="text-center">
              <div><IcoDot /></div>
            </td>
            <td class="text-center">
              <div><IcoUpdate /></div>
            </td>
            <td class="text-center">
              <div><IcoDelete /></div>
            </td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Jacob</td>
            <td>Thornton</td>
            <td class="text-center">
              <div><IcoFile /></div>
            </td>
            <td class="text-center">
              <div><IcoDot /></div>
            </td>
            <td class="text-center">
              <div><IcoUpdate /></div>
            </td>
            <td class="text-center">
              <div><IcoDelete /></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import IcoFile from "vue-material-design-icons/FileAccount.vue";
import IcoDot from "vue-material-design-icons/FormatListText.vue";
import IcoUpdate from "vue-material-design-icons/Check.vue";
import IcoDelete from "vue-material-design-icons/Close.vue";

export default {
  name: "OffreListe",
  components: {
    IcoFile,
    IcoDot,
    IcoUpdate,
    IcoDelete,
  },
};
</script>

<style lang="scss" scoped></style>
